<template>
  <div>
    <div @click="ddddd">ceshi</div>
  </div>
</template>

<script>
import {ceshi} from '../../api/classroon/xueqi'
export default {
  methods: {
    ddddd(){
      ceshi().then(res=>{
        console.log(res);
      })
    }
  },
}
</script>

<style>

</style>